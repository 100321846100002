import { onMounted, defineComponent, reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { doPayDeposit } from '@/api/user';
import { Toast } from 'vant';
import 'vant/es/toast/style';
import wx from 'weixin-js-sdk';
export default defineComponent({
  name: 'Deposit',
  components: {},

  setup() {
    const state = reactive({
      code_sn: '',
      // 二维码编号
      amount: ''
    });
    const route = useRoute();
    onMounted(() => {
      const query = route.query;
      state.code_sn = query.code_sn;
      state.amount = query.amount;
    });
    /**
     * 支付
     */

    const onAuthPay = async () => {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const sn = state.code_sn; // 挪车码编号

      const {
        data,
        status,
        msg
      } = await doPayDeposit({
        sn
      });
      toast.clear();

      if (status != 1) {
        return Toast.fail(msg);
      }

      wx.miniProgram.postMessage({
        data: {
          payment: data,
          msg: '车主隐私号码已获取',
          type: 'scan',
          sn
        }
      });
      wx.miniProgram.reLaunch({
        url: '/pages/payment/index'
      });
    };

    return { ...toRefs(state),
      onAuthPay
    };
  }

});